import styled from 'styled-components';
import { COLORS } from '@ali/we-design-token';
import { BaseSection } from '../common/BaseSection';
import { mobileWidthBreakPoint } from '../../styles';

export const Container = styled(BaseSection)`
  background: linear-gradient(0deg, #fff 60%, rgba(255, 255, 255, 0) 100%);
`;

export const Content = styled.div`
  padding: 88px 0;

  .title {
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
  }

  .desc {
    font-size: 24px;
    line-height: 28px;
    margin-top: 16px;
    color: ${COLORS.LEVEL2};
  }

  .btn {
    margin-top: 24px;
    display: flex;
    align-items: center;

    &.mb {
      display: none;
    }

    img {
      margin-left: 12px;
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    padding: 44px 24px;

    .title {
      font-size: 24px;
      line-height: 36px;
    }

    .desc {
      display: none;
    }

    .btn {
      min-width: 148px;

      &.pc {
        display: none;
      }
      &.mb {
        display: flex;
      }
    }
  }
`;
