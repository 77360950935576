const resource: {[key: string]: string} = {
  "doc_oa_dingding_document_background_management": "釘釘文檔管理後台",
  "doc_oa_enterprise_settings": "企業設置",
  "doc_oa_data_report": "數據報表",
  "doc_oa_log_audit": "日誌審計",
  "doc_oa_resignation": "離職交接",
  "doc_oa_save_settings": "保存設置",
  "doc_oa_server_wrong": "系統錯誤",
  "doc_oa_save_succeed": "保存成功 ",
  "doc_oa_data_dashboard": "数据看板",
  "doc_oa_active_docs": "活躍文檔",
  "doc_oa_active_users": "活躍用戶",
  "doc_oa_active_member_list": "Top 50 成員活躍列表",
  "doc_oa_request_fail": "請求失敗",
  "doc_oa_request_fail_desc": "數據請求失敗,請重試",
  "doc_oa_refetch": "重試",
  "doc_oa_sort": "排序",
  "doc_oa_storage_management": "容量管理",
  "doc_oa_active_license_management": "許可證管理"
};

export default resource