import React from 'react';
import { Container, Content } from './styles';

const imageUrls = [
  'https://img.alicdn.com/imgextra/i2/O1CN01szvJaP1CoDGE9I3GF_!!6000000000127-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i4/O1CN01CIZa1D1WSxBsHLzon_!!6000000002788-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i3/O1CN01Zxb67E28XUVypgToe_!!6000000007942-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i1/O1CN01gaR1DQ1zJRpS5ZtTd_!!6000000006693-2-tps-283-108.png',
  'https://img.alicdn.com/imgextra/i1/O1CN01iSAG9b22gIR0Zet7b_!!6000000007149-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i3/O1CN017ug92i1gygnC34ozA_!!6000000004211-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i4/O1CN01qLatxQ1nfdd04Zt9g_!!6000000005117-2-tps-283-108.png',
  'https://img.alicdn.com/imgextra/i3/O1CN01k1v2ES1LJN8FjxQBB_!!6000000001278-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i2/O1CN01SLAuqH1f2Ed0BW7h9_!!6000000003948-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i3/O1CN01Tx9NMa1R9C1VpS5Cf_!!6000000002068-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i1/O1CN01vCztfE1J1qkQ0MwBX_!!6000000000969-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i1/O1CN01hggtzX1NwQ8CnSvck_!!6000000001634-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i1/O1CN01rFhyOL1UHqM1mkiUm_!!6000000002493-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i2/O1CN01XWaL5I29NFGu0AAcW_!!6000000008055-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i1/O1CN01KOtncG29vaydLXKbn_!!6000000008130-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i1/O1CN01ru4TRv1cpICnB9n9P_!!6000000003649-2-tps-283-108.png',
  'https://img.alicdn.com/imgextra/i4/O1CN01fcbK5T1P4UjS5g2Z1_!!6000000001787-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i1/O1CN01w5uRvn1vWUeHUfAau_!!6000000006180-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i4/O1CN01eoWGD01XzlBAjY31P_!!6000000002995-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i4/O1CN01BfN9MV29NFGxBGfV6_!!6000000008055-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i3/O1CN01RYhkXX1ydlmx9ZZTy_!!6000000006602-0-tps-283-108.jpg',
  'https://img.alicdn.com/imgextra/i3/O1CN015oY8Mm1MctdM7DeD9_!!6000000001456-2-tps-283-108.png',
  'https://img.alicdn.com/imgextra/i3/O1CN016eXY7P1s6RGnBohEN_!!6000000005717-2-tps-283-108.png',
  'https://img.alicdn.com/imgextra/i1/O1CN016KUJHi241C7imyGW0_!!6000000007330-2-tps-283-108.png',
];

export const Customers = () => {
  return (
    <Container>
      <Content>
        <div className="title">各行各业都在用钉钉文档</div>
        <div className="list">
          {imageUrls.map((url) => (
            <div key={url} className="item" style={{ backgroundImage: `url(${url})` }} />
          ))}
        </div>
      </Content>
    </Container>
  );
};
