import styled from 'styled-components';
import { BaseSection } from '../common/BaseSection';
import { mobileWidthBreakPoint } from '../../styles';

export const Container = styled(BaseSection)`
  background-color: transparent;
`;

export const Content = styled.div`
  padding: 60px 0;

  .title {
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    font-weight: 600;
  }

  .list {
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-bottom: -16px;

    .item {
      margin-right: 16px;
      margin-bottom: 16px;
      width: calc(20% - 16px);
      height: 67.39px;
      border-radius: 8px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #fff;
      overflow: hidden;
    }
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    padding: 44px 16px;

    .title {
      font-size: 24px;
      line-height: 36px;
    }

    .list {
      margin-top: 32px;
      margin-right: -8px;
      margin-bottom: -8px;

      .item {
        width: calc(33% - 8px);
        height: 44px;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
`;
