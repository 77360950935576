import React from 'react';
import { Container, Content } from './styles';

export const Footer = () => {
  return (
    <Container>
      <Content>
        <div className="record">
          <img src="https://gw.alicdn.com/tfs/TB1GxwdSXXXXXa.aXXXXXXXXXXX-65-70.gif" alt="" />
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">沪ICP备11014111号-15</a>
        </div>
      </Content>
    </Container>
  );
};
