import React, { ReactElement } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { GlobalStyle } from '../../../styles';

export const AppCommonEntry = ({ children }: { children: ReactElement }) => {
  return (
    <>
      <GlobalStyle />
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </>
  );
};

AppCommonEntry.displayName = 'AppCommonEntry';
