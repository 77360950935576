import React from 'react';
import cx from 'classnames';
import { Button } from '../common/Button';
import { Container, Content } from './styles';

export const Links = () => {
  const renderActionBtn = (text: string, cls: string, size: 'small' | 'medium' = 'medium') => {
    return (
      <Button
        className={cx('btn', cls)}
        onClick={() => {
          window.open('/login', '_self');
        }}
        size={size}
      >
        {text}
        <img src="https://img.alicdn.com/imgextra/i4/O1CN01dWP5u01PV3he29zVF_!!6000000001845-55-tps-24-24.svg" />
      </Button>
    );
  };

  return (
    <Container>
      <Content>
        <div className="title">准备好开启高效创作之旅了吗？</div>
        <div className="desc">安全、智能、协作、新一代知识创作与管理平台</div>
        {renderActionBtn('注册开启创作', 'pc')}
        {renderActionBtn('免费体验', 'mb', 'small')}
      </Content>
    </Container>
  );
};
