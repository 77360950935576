import React from 'react';
import { Button } from '../common/Button';
import { Container } from './styles';
import { VersionCardList } from './VersionCard';

export const Cover = () => {
  return (
    <Container>
      <div className="title-wrapper">
        <h1 className="title">钉钉文档企业版</h1>
        <p className="desc">企业级知识创作与管理平台</p>
        <div className="button-wrapper">
          <Button
            onClick={() => {
              window.open('/login', '_self');
            }}
          >
            免费体验
          </Button>
          <Button
            type="secondary"
            onClick={() => {
              window.open(
                'https://alidocs.dingtalk.com/notable/share/form/v018K4nyRm4GvPeqLbj_hERWDMS_mkdjOhk',
                '_blank',
              );
            }}
          >
            购买咨询
          </Button>
        </div>
      </div>
      <VersionCardList />
    </Container>
  );
};
