import { COLORS } from '@ali/we-design-token';
import styled from 'styled-components';
import { mobileWidthBreakPoint, scrollbarHidden } from '../../styles';

export const Container = styled.div`
  background: linear-gradient(0deg, #fff -3%, rgba(255, 255, 255, 0) 50%);
  width: 100%;
  padding: 88px 0 64px;

  .title-wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 56px;
      font-weight: 600;
      line-height: 78px;
      margin: 0;
    }

    .desc {
      font-size: 27px;
      line-height: 38px;
      color: ${COLORS.LEVEL2};
      margin: 0;
      margin-top: 8px;
    }

    .button-wrapper {
      margin-top: 36px;
      display: flex;
      align-items: center;
      justify-items: center;

      button + button {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    & {
      padding: 60px 0 36px;
    }

    .title-wrapper {
      .title {
        font-size: 36px;
        line-height: 50px;
      }
      .desc {
        font-size: 18px;
        line-height: 25px;
        margin-top: 4px;
      }
      .button-wrapper {
        flex-direction: column;
        width: 100%;
        min-width: unset;

        button {
          height: 40px;
          font-size: 16px;
        }

        button + button {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
`;

export const VersionCardListContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
  padding-bottom: 24px;

  & > * + * {
    margin-left: 32px;
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    & {
      margin-top: 0;
      padding-top: 44px;
      padding-left: 16px;
      padding-right: 16px;

      & > * + * {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 860px) {
    ${scrollbarHidden}
  }
`;

export const VersionCardContainer = styled.div`
  width: 274px;
  flex-shrink: 0;
  background: ${COLORS.FG_LIGHT};
  border: 1px solid ${COLORS.LINE_HEAVY};
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-4px);
  }

  .header {
    width: 100%;
    height: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 26px;
      font-weight: 600;
      line-height: 24px;
    }

    .desc {
      font-size: 14px;
      line-height: 24px;
      margin-top: 8px;
      color: ${COLORS.LEVEL2};
      font-weight: 500;
    }
  }

  .body {
    padding: 24px;

    .feature-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .item {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        height: 24px;
        color: ${COLORS.LEVEL2};

        svg {
          color: ${COLORS.GREEN1};
          margin-right: 8px;
        }

        & + .item {
          margin-top: 8px;
        }
      }
    }

    .price {
      margin-top: 28px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: baseline;

      .currency {
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
        color: #171a1d;
      }
      .value {
        font-size: 48px;
        line-height: 36px;
        font-weight: bold;
        margin-left: 8px;
      }
      .unit {
        font-size: 14px;
        line-height: 18px;
        color: ${COLORS.LEVEL3};
        margin-left: 8px;
      }
    }

    .button {
      margin-top: 20px;
      width: 100%;
      min-width: unset;
    }
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    & {
      width: 260px;

      .header {
        height: 100px;

        .title {
          font-size: 24px;
        }
      }

      .body {
        .price {
          .value {
            font-size: 40px;
            line-height: 35px;
          }
        }
      }
    }
  }
`;
