import React from 'react';
import { BaseSection } from '../common/BaseSection';
import { AppsContainer } from './styles';

interface AppItem {
  title: string;
  desc: string;
  icon: string;
  color: string;
}

export const Apps = () => {
  const apps: AppItem[] = [
    {
      title: '钉钉文字',
      desc: '聪明美观的创作平台',
      icon: 'https://img.alicdn.com/imgextra/i4/O1CN01Xf5V0v1YNZlxdd00Y_!!6000000003047-55-tps-32-32.svg',
      color: '#f4f8ff',
    },
    {
      title: '钉钉表格',
      desc: '数据洞察的助力工具',
      icon: 'https://img.alicdn.com/imgextra/i2/O1CN01e7Md2J1qRqY73Jvfn_!!6000000005493-55-tps-32-32.svg',
      color: '#eefcf6',
    },
    {
      title: '钉钉多维表',
      desc: '创新数据展现方式',
      icon: 'https://img.alicdn.com/imgextra/i2/O1CN014m4kxx1qwX5N08NDC_!!6000000005560-55-tps-32-32.svg',
      color: '#f4feff',
    },
    {
      title: '钉钉脑图',
      desc: '思维结构的清晰图谱',
      icon: 'https://img.alicdn.com/imgextra/i1/O1CN01DzADkl1mHzZ1Je4kc_!!6000000004930-55-tps-32-32.svg',
      color: 'rgba(113, 66, 253, 0.06)',
    },
    {
      title: '知识库',
      desc: '最美文档',
      icon: 'https://img.alicdn.com/imgextra/i3/O1CN01TTYFsG1YD2fTUFbRa_!!6000000003024-2-tps-96-96.png',
      color: '#f4feff',
    },
  ];

  return (
    <BaseSection>
      <AppsContainer>
        <div className="title">内容创作与知识管理</div>
        <div className="desc">提供丰富的创作套件和完整知识管理应用</div>
        <div className="apps">
          {apps.map((app) => (
            <div key={app.title} className="app" style={{ background: app.color }}>
              <div
                className="icon"
                style={{ background: `center / cover no-repeat url(${app.icon}` }}
              />
              <div className="title">{app.title}</div>
              <div className="desc">{app.desc}</div>
            </div>
          ))}
        </div>
      </AppsContainer>
    </BaseSection>
  );
};
