const resource: {[key: string]: string} = {
  "doc_oa_dingding_document_background_management": "钉钉文档管理后台",
  "doc_oa_enterprise_settings": "企业设置",
  "doc_oa_data_report": "数据报表",
  "doc_oa_log_audit": "日志审计",
  "doc_oa_resignation": "离职交接",
  "doc_oa_save_settings": "保存设置",
  "doc_oa_server_wrong": "系统错误",
  "doc_oa_save_succeed": "保存成功",
  "doc_oa_data_dashboard": "数据看板",
  "doc_oa_active_docs": "活跃文档",
  "doc_oa_active_users": "活跃用户",
  "doc_oa_active_member_list": "Top 50 成员活跃列表",
  "doc_oa_request_fail": "请求失败",
  "doc_oa_request_fail_desc": "数据请求失败，请重试",
  "doc_oa_refetch": "重试",
  "doc_oa_sort": "排序",
  "doc_oa_storage_management": "容量管理",
  "doc_oa_active_license_management": "许可证管理"
};

export default resource