import React from 'react';
import { Colorcheck16 } from '@ali/we-icons';
import { Button } from '../common/Button';
import { VersionCardContainer, VersionCardListContainer } from './styles';

export enum EnterpriseVersion {
  FREE = 'free',
  ENTERPRISE = 'enterprise',
  PRIVATE = 'private',
}

interface Config {
  title: string;
  desc: string;
  descColor?: string;
  bg: string;
  features: string[];
  price?: number;
  btn: {
    text: string;
    action?: 'redirect';
    link: string;
  };
}

const versionMap: Record<EnterpriseVersion, Config> = {
  [EnterpriseVersion.FREE]: {
    title: '体验版',
    desc: '免费试用 30 天，深度体验',
    bg: 'linear-gradient(194deg, #ededed 97%, rgba(255,255,255,0.00) 153%), #fff',
    features: [
      '支持至多 10 人加入企业',
      '存储空间 2G/人',
      '基础安全管控与企业管理',
      '高效文档协作与分享',
      '',
    ],
    price: 0,
    btn: {
      text: '免费试用',
      action: 'redirect',
      link: '/login',
    },
  },
  [EnterpriseVersion.ENTERPRISE]: {
    title: '企业版',
    desc: '高性价比之选，全面助力企业提效',
    descColor: 'rgba(0, 39, 106, 0.6)',
    bg: 'url(https://img.alicdn.com/imgextra/i3/O1CN012Om69N1tZ7dCjwN78_!!6000000005915-2-tps-562-244.png)',
    features: [
      '存储空间 100G/人',
      '文档、表格、多维表等多种应用',
      '操作日志等高阶安全能力',
      '文档与知识库数据一目了然',
      '丰富模板内容，并支持自定义',
    ],
    price: 198,
    btn: {
      text: '立即购买',
      link: 'https://alidocs.dingtalk.com/notable/share/form/v018K4nyRm4GvPeqLbj_nYeA6xB_mlVij5K',
    },
  },
  [EnterpriseVersion.PRIVATE]: {
    title: '私有版',
    desc: '本地独立部署，数据安全自主可控',
    descColor: 'rgba(91, 36, 10, 0.6)',
    bg: 'url(https://img.alicdn.com/imgextra/i3/O1CN01fVWY3I1xZohFWE5yX_!!6000000006458-2-tps-564-244.png)',
    features: [
      '基于企业本地服务器部署',
      '畅享企业版高阶能力',
      '灵活集成企业自有 OA 底座',
      '打通企业业务系统与数据',
      '海量 API 接口全面开放',
    ],
    btn: {
      text: '购买咨询',
      link: 'https://alidocs.dingtalk.com/notable/share/form/v018K4nyRm4GvPeqLbj_Tl9MdvG_fpK6y0q',
    },
  },
};

interface Props {
  type: EnterpriseVersion;
}

export const VersionCard = ({ type }: Props) => {
  const config = versionMap[type];

  const handleClick = () => {
    if (config.btn.action === 'redirect') {
      window.open(config.btn.link, '_self');
    } else {
      window.open(config.btn.link, '_blank');
    }
  };

  return (
    <VersionCardContainer onClick={handleClick}>
      <div className="header" style={{ background: config.bg, backgroundSize: 'cover' }}>
        <div className="title">{config.title}</div>
        <div className="desc" style={{ color: config.descColor }}>{config.desc}</div>
      </div>
      <div className="body">
        <div className="feature-list">
          {config.features.map((feature) => (
            <div key={feature} className="item">
              { feature
                ? (
                  <>
                    <Colorcheck16 />
                    {feature}
                  </>
                )
                : null }
            </div>
          ))}
        </div>
        <div className="price">
          {config.price !== undefined
            ? (
              <>
                <span className="currency">¥</span>
                <span className="value">{config.price}</span>
                <span className="unit">人/年</span>
              </>
            )
            : null}
        </div>
        <Button className="button" size="small">{config.btn.text}</Button>
      </div>
    </VersionCardContainer>
  );
};

export const VersionCardList = () => {
  return (
    <VersionCardListContainer>
      {Object.keys(versionMap).map((key) => (
        <VersionCard key={key} type={key as EnterpriseVersion} />
      ))}
    </VersionCardListContainer>
  );
};
