import { COLORS } from '@ali/we-design-token';
import styled from 'styled-components';
import { mobileWidthBreakPoint } from '../../styles';

export const AppsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 88px 0;
  width: 100%;

  .title {
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
  }

  .desc {
    font-size: 20px;
    line-height: 28px;
    margin-top: 16px;
    color: ${COLORS.LEVEL2};
  }

  .apps {
    display: flex;
    margin-top: 32px;
    width: 100%;

    .app {
      flex: 1 0;
      padding: 28px 20px;
      margin-top: 16px;
      border-radius: 12px;

      &:not(:first-child) {
        margin-left: 16px;
      }

      .icon {
        width: 32px;
        height: 32px;
      }
      
      .title {
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
        margin-top: 30px;
      }

      .desc {
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.LEVEL2};
        margin-top: 4px;
      }
    }
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    padding-top: 0;
    padding-bottom: 32px;

    .title {
      font-size: 28px;
      line-height: 34px;
    }

    .desc {
      font-size: 15px;
      line-height: 22px;
      margin-top: 12px;
    }

    .apps {
      flex-wrap: wrap;
      padding: 0 8px 0 24px;

      .app {
        flex: none;
        width: calc(33% - 16px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 12px;
        margin-right: 16px;

        &:not(:first-child) {
          margin-left: 0;
        }

        .title {
          font-size: 14px;
          line-height: 20px;
          margin-top: 16px;
        }

        .desc {
          display: none;
        }
      }
    }
  }
`;
