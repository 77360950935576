import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { Container, Content } from './styles';

interface ExampleItem {
  orgName: string;
  title: string;
  img: string;
  link: string;
}

const examples: ExampleItem[] = [
  {
    orgName: '喜马拉雅',
    title: '钉钉文档让内容协作更简单，实现跨部门高效协同',
    img: 'https://img.alicdn.com/imgextra/i2/O1CN01hGJBGs28bc4yiWWbi_!!6000000007951-0-tps-539-776.jpg',
    link: 'https://alidocs.dingtalk.com/i/nodes/vy20BglGWOgeMlyBIbE6G9lrWA7depqY?utm_scene=team_space',
  },
  {
    orgName: '艾为芯片',
    title: '钉钉文档助力企业实现信息「高效」与「安全」的双赢',
    img: 'https://img.alicdn.com/imgextra/i2/O1CN01E2KAzi1TS5b0gvy4y_!!6000000002380-0-tps-539-776.jpg',
    link: 'https://alidocs.dingtalk.com/i/nodes/o14dA3GK8g45zXMKH6l4o3zPW9ekBD76?utm_scene=team_space',
  },
  {
    orgName: '佳沃集团',
    title: '用知识库提升“核心知识竞争力” ，让组织创新加速',
    img: 'https://img.alicdn.com/imgextra/i1/O1CN01EbPVvk1hBVsgblsuh_!!6000000004239-0-tps-539-776.jpg',
    link: 'https://alidocs.dingtalk.com/i/nodes/amweZ92PV66vyGQltyre71jxVxEKBD6p?utm_scene=team_space',
  },
  {
    orgName: '蒙牛',
    title: '在线文档如何助力超大型组织效能提升，让协作更高效',
    img: 'https://img.alicdn.com/imgextra/i3/O1CN01Y7FOq21jsgRmlUkmn_!!6000000004604-0-tps-539-776.jpg',
    link: 'https://alidocs.dingtalk.com/i/nodes/YndMj49yWjDPmzk9IxALbAQnJ3pmz5aA?utm_scene=team_space',
  },
  {
    orgName: '上海三菱电梯',
    title: '如何用“钉钉”连接百万级电梯、用户与员工',
    img: 'https://img.alicdn.com/imgextra/i1/O1CN01VnbhAO248WSafJKuv_!!6000000007346-0-tps-539-776.jpg',
    link: 'https://alidocs.dingtalk.com/i/nodes/gvNG4YZ7JnNebnzjFeGrgql2J2LD0oRE?utm_scene=team_space',
  },
  {
    orgName: '小电科技',
    title: '挖掘钉钉文档宝藏功能，让全员实现知识的“共享充电”',
    img: 'https://img.alicdn.com/imgextra/i2/O1CN01fQKyhe1YhjCOcHIjc_!!6000000003091-0-tps-539-776.jpg',
    link: 'https://alidocs.dingtalk.com/i/nodes/YndMj49yWjDPmzk9I79Ro73qJ3pmz5aA?utm_scene=team_space',
  },
];

export const Examples = () => {
  const [scrollDisabled, setScrollDisabled] = useState('left');
  const cardsEl = useRef<HTMLDivElement>(null);

  const handleScroll = (dir: 'left' | 'right') => {
    if (!cardsEl.current) {
      return;
    }
    const listEl = cardsEl.current;
    const cardEl = listEl.children[0];

    const cardWidth = cardEl.getBoundingClientRect().width;
    const cardMargin = parseInt(getComputedStyle(cardEl).marginLeft, 10);
    const moveStep = cardWidth + cardMargin;

    const left = parseInt(getComputedStyle(listEl).left, 10) || 0;

    setScrollDisabled('');
    if (dir === 'left') {
      const nextLeft = left + moveStep >= 0 ? 0 : left + moveStep;
      listEl.style.left = `${nextLeft}px`;
      if (nextLeft === 0) {
        setScrollDisabled('left');
      }
    } else {
      const listWidth = listEl.getBoundingClientRect().width;
      const windowWidth = window.innerWidth;
      const maxMoveLeft = windowWidth - listWidth;
      const nextLeft = left - moveStep <= maxMoveLeft ? maxMoveLeft : left - moveStep;
      listEl.style.left = `${nextLeft}px`;
      if (nextLeft === maxMoveLeft) {
        setScrollDisabled('right');
      }
    }
  };

  return (
    <Container>
      <Content>
        <div className="title">钉钉文档助力<br />创新企业更好地协作</div>
        <div className="cards-wrapper">
          <div className="cards" ref={cardsEl}>
            {examples.map((item) => (
              <div
                className="card"
                key={item.title}
                style={{ backgroundImage: `url(${item.img})` }}
                onClick={() => {
                  window.open(item.link, '_blank');
                }}
              >
                <div className="org">{item.orgName}</div>
                <div className="title">{item.title}</div>
              </div>
            ))}
            <div
              className="check-more"
              onClick={() => {
                window.open(
                  'https://alidocs.dingtalk.com/i/nodes/R1zknDm0WRN3kwPqFNQ69GqaWBQEx5rG?utm_scene=team_space',
                  '_blank',
                );
              }}
            >
              <span>
                查<br />看<br />更<br />多
              </span>
              <i className="dd-home-head-icon">&#xe871;</i>
            </div>
          </div>
        </div>
        <div className="scroller">
          <div
            className={cx('item', { disabled: scrollDisabled === 'left' })}
            onClick={() => handleScroll('left')}
          >
            <i className="dd-home-head-icon">&#xe86e;</i>
          </div>
          <div
            className={cx('item', { disabled: scrollDisabled === 'right' })}
            onClick={() => handleScroll('right')}
          >
            <i className="dd-home-head-icon">&#xe871;</i>
          </div>
        </div>
      </Content>
    </Container>
  );
};
