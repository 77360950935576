const resource: {[key: string]: string} = {
  "doc_oa_dingding_document_background_management": "AliDocs管理システム",
  "doc_oa_enterprise_settings": "組織設定",
  "doc_oa_data_report": "データレポート",
  "doc_oa_log_audit": "ログ監査",
  "doc_oa_resignation": "退職対応",
  "doc_oa_save_settings": "設定を保存する",
  "doc_oa_server_wrong": "サーバー エラー",
  "doc_oa_save_succeed": "保存が完了した",
  "doc_oa_data_dashboard": "パフォーマンスかんばん",
  "doc_oa_active_docs": "アクティブなドキュメント",
  "doc_oa_active_users": "アクティブユーザー",
  "doc_oa_active_member_list": "トップ50メンバーアクティブリスト",
  "doc_oa_request_fail": "要求失敗した",
  "doc_oa_request_fail_desc": "データリクエストに失敗しました。もう一度お試しください",
  "doc_oa_refetch": "再試行",
  "doc_oa_sort": "並べ替え",
  "doc_oa_storage_management": "容量管理",
  "doc_oa_active_license_management": "ライセンス管理"
};

export default resource