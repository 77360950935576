import { css } from 'styled-components';
import { COLORS } from '@ali/we-design-token';

export const normalStyle = css`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 14px;
    background-color: ${COLORS.BG_DEFAULT};
    color: ${COLORS.LEVEL1};
    /* 防止被后面的组件依赖的不同版本dingtalk design desktop覆盖 */
    font-family: -apple-system, 'pingfang sc', 'San Francisco Pro', '思源黑体', 'Roboto', 'Arial',
      'Microsoft YaHei','Hiragino Sans GB', sans-serif, Helvetica, '黑体', '宋体' !important;
    font-variant-ligatures: no-common-ligatures;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
  }
  html body {
    background-color: ${COLORS.BG_DEFAULT};
    color: ${COLORS.LEVEL1};
  }
  body {
    h1, h2, h3, h4, h5, h6 {
      color: ${COLORS.LEVEL1};
    }
  }

  *, :after, :before {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
  }

  a, a:hover, a:active {
    color: inherit;
  }

  html[data-dingtalk-theme="dark"] {
    /* dark 模式下，文档类型图标透明度为 85% */
    .wefile-icon-container {
      opacity: 0.85;
    }
    /* dark 模式下，运营位透明度为 85% */
    .activity-list-wrap {
      opacity: 0.85;
    }
  }
  html .dtd-checkbox-wrapper .dtd-checkbox .dtd-checkbox-inner {
    border-color: ${COLORS.LEVEL4};
  }

  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: var(--common_line_light_color, hsla(210, 7%, 53%, .16));

    &:hover {
      background: var(--common_line_hard_color, hsla(210, 7%, 53%, .24));
    }
  }
  /* 滚动条边角 */
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
