import styled, { createGlobalStyle, css } from 'styled-components';

export const mobileWidthBreakPoint = '1023px';
export const scrollbarHidden = css`
  & {
    justify-content: flex-start;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &:-webkit-scrollbar {
      display: none; /* Safari 和 Chrome */
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'IconFont';  /* Project id 135284 */
    src: url('//at.alicdn.com/t/c/font_135284_eorwvnjcsaj.woff2?t=1716020693161') format('woff2'),
      url('//at.alicdn.com/t/c/font_135284_eorwvnjcsaj.woff?t=1716020693161') format('woff'),
      url('//at.alicdn.com/t/c/font_135284_eorwvnjcsaj.ttf?t=1716020693161') format('truetype');
  }

  .dd-home-head-icon {
    font-family: 'IconFont' !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const Container = styled.div`
  background: rgba(235, 243, 255, 0.66);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
