import styled from 'styled-components';

export const ErrorActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * + * {
    margin-top: 4px;
  }

  .link-button {
    color: var(--common_blue1_color, #007fff);
    font-size: 14px;
    line-height: 36px;
    border: none;
  }
`;
