const resource: {[key: string]: string} = {
  "doc_oa_dingding_document_background_management": "AliDocs management system",
  "doc_oa_enterprise_settings": "Enterprise settings",
  "doc_oa_data_report": "Data report",
  "doc_oa_log_audit": "Log audit",
  "doc_oa_resignation": "Resignation Handover",
  "doc_oa_save_settings": "Save Settings",
  "doc_oa_server_wrong": "Server error",
  "doc_oa_save_succeed": "Saved successfully",
  "doc_oa_data_dashboard": "Admin insights",
  "doc_oa_active_docs": "Active document",
  "doc_oa_active_users": "Active user",
  "doc_oa_active_member_list": "Top 50 member active list",
  "doc_oa_request_fail": "Request failed",
  "doc_oa_request_fail_desc": "Data request failed, please try again",
  "doc_oa_refetch": "Retry",
  "doc_oa_sort": "Rank",
  "doc_oa_storage_management": "Capacity Management",
  "doc_oa_active_license_management": "License Management"
};

export default resource