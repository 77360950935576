import styled from 'styled-components';
import { COLORS } from '@ali/we-design-token';
import { BaseSection } from '../common/BaseSection';
import { mobileWidthBreakPoint, scrollbarHidden } from '../../styles';

export const Container = styled(BaseSection)`
  background: linear-gradient(0deg, #fff 7%, rgba(255, 255, 255, 0) 67%);

  & > .content {
    min-width: 100%;
    max-width: 100%;
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 88px 0;

  & > .title {
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    text-align: center;
  }

  .cards-wrapper {
    overflow: hidden;
    display: flex;
  }

  .cards {
    padding: 0 60px;
    margin-top: 48px;
    margin-bottom: 40px;
    display: flex;
    position: relative;
    transition: left 0.3s ease;
    left: 0;

    .card {
      flex-shrink: 0;
      width: 307px;
      height: 440px;
      border-radius: 8px;
      overflow: hidden;
      padding: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;

      .org {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.66);
        line-height: 20px;
      }

      .title {
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
        margin-top: 8px;
        color: #fff;
      }
    }

    .check-more {
      width: 80px;
      height: 440px;
      border-radius: 8px;
      background-color: ${COLORS.OVERLAY_LIGHT};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
      }

      i {
        margin-left: 8px;
        font-size: 12px;
      }

      &:hover {
        background-color: ${COLORS.OVERLAY_HEAVY};
      }
    }

    & > * + * {
      margin-left: 24px;
    }
  }

  .scroller {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    .item {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background-color: ${COLORS.OVERLAY_LIGHT};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: ${COLORS.LEVEL2};
      cursor: default;

      &:not(:first-child) {
        margin-left: 24px;
      }

      &:not(.disabled):hover {
        background-color: ${COLORS.OVERLAY_HEAVY};
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    & {
      padding: 44px 0;
    }

    & > .title {
      font-size: 24px;
      line-height: 36px;
    }

    .cards {
      padding: 0 24px;
      margin-top: 32px;
      margin-bottom: 0;
      width: 100%;
      overflow-x: auto;
      ${scrollbarHidden}

      .card {
        width: 271px;
        height: 388px;
        padding: 20px;
        flex-shrink: 0;

        .org {
          font-size: 12px;
          line-height: 18px;
        }

        .title {
          font-size: 20px;
          line-height: 30px;
          margin-top: 8px;
        }
      }

      .check-more {
        width: 64px;
        height: 388px;
        flex-shrink: 0;

        span {
          font-size: 14px;
        }
      }
    }

    .scroller {
      display: none;
    }
  }
`;
