import { COLORS } from '@ali/we-design-token';
import styled from 'styled-components';
import React from 'react';
import { mobileWidthBreakPoint } from '../../../styles';

const MaxContentContainerWidth = 1172;
const MinContentContainerWidth = 904;
export const Section = styled.section`
  width: 100%;
  background: ${COLORS.FG_LIGHT};
  display: flex;
  justify-content: center;

  & > .content {
    max-width: ${MaxContentContainerWidth}px;
    min-width: ${MinContentContainerWidth}px;
    width: 100%;
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    & > .content {
      max-width: 100%;
      min-width: 100%;
      padding: 0;
    }
  }

  @media (
    min-width: ${mobileWidthBreakPoint}) and (max-width: ${MaxContentContainerWidth + 120}px
  ) {
    & > .content {
      padding: 0 60px;
    }
  }
`;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const BaseSection = ({ children, className, style }: Props) => {
  return (
    <Section className={className} style={style}>
      <div className="content">{children}</div>
    </Section>
  );
};
