import React from 'react';
import { Button } from '@ali/we-design-next';
import { i18next } from '@ali/dingtalk-i18n';
import { ErrorActionsWrapper } from './styles';

export const PageErrorActions = () => {
  return (
    <ErrorActionsWrapper>
      <Button
        size="normal"
        type="primary"
        onClick={() => {
          // 刷新页面
          location.reload();
        }}
      >
        {i18next.t('刷新页面')}
      </Button>
    </ErrorActionsWrapper>
  );
};
