import styled from 'styled-components';
import { COLORS } from '@ali/we-design-token';
import { BaseSection } from '../common/BaseSection';
import { mobileWidthBreakPoint } from '../../styles';

export const FeatureWrapper = styled(BaseSection)`
  padding: 80px 0;

  &:nth-child(odd) {
    background: #ecf3ff;
  }

  &:nth-child(even) {
    background: #f0f8ff;
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    padding: 44px 0;
  }
`;

export const FeatureBody = styled.div`
  display: flex;

  .left {
    width: 460px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon {
      width: 32px;
      height: 32px;
      margin-left: 2px;
    }

    .title {
      font-size: 36px;
      line-height: 50px;
      font-weight: 600;
      margin-top: 16px;

      .highlight {
        color: #3f85ff;
      }
    }

    .tips {
      margin-top: 16px;

      .tip {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        color: ${COLORS.LEVEL2};

        svg {
          margin-right: 12px;
          flex-shrink: 0;
        }

        & + .tip {
          margin-top: 12px;
        }
      }
    }
  }

  .right {
    flex: 1;
    margin-left: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }

    .mb-img {
      display: none;
    }
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    flex-direction: column;

    .left {
      width: 100%;
      align-items: center;

      .icon {
        display: none;
      }

      .title {
        font-size: 24px;
        line-height: 36px;
      }

      .tips {
        margin-top: 12px;
        padding: 0 12px;

        .tip {
          font-size: 15px;
          line-height: 22px;
          justify-content: center;

          svg {
            display: none;
          }

          & + .tip {
            margin-top: 8px;
          }
        }
      }
    }

    .right {
      margin-left: 0;
      padding: 0 24px;

      .pc-img {
        display: none;
      }
      .mb-img {
        display: block;
      }
    }
  }
`;
