import React from 'react';
import { i18next } from '@ali/dingtalk-i18n';
import { BaseEmpty } from '@ali/dingdoc-portal-components-common/lib/Empty';
import { PageErrorActions } from './PageErrorActions';

export const PageError = ({
  fullScreen,
}: {
  fullScreen?: boolean;
}) => {
  return (
    <BaseEmpty
      screenCenter={fullScreen}
      full={!fullScreen}
      type="exception"
      title={i18next.t('页面异常')}
      actions={<PageErrorActions />}
    />
  );
};
