import { COLORS } from '@ali/we-design-token';
import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  size?: 'small' | 'medium';
  type?: 'primary' | 'secondary';
  onClick?: () => void;
}

const ButtonEl = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: ${COLORS.BRAND1};
  color: ${COLORS.FG_LIGHT};
  border-width: 0;
  min-width: 188px;
  padding: 8px 24px;
  height: 48px;
  font-size: 18px;
  font-weight: 600;

  &.secondary {
    background: ${COLORS.FG_LIGHT};
    border: 1px solid ${COLORS.BRAND1};
    color: ${COLORS.BRAND1};
  }

  &.small {
    height: 40px;
    font-size: 14px;
    padding: 4px 16px;
  }

  &:hover {
    background: ${COLORS.BRAND2};
  }

  &.secondary:hover {
    background: ${COLORS.BG_LIGHT};
  }
`;

export const Button = ({
  children,
  className,
  style,
  size = 'medium',
  type = 'primary',
  onClick,
}: Props) => {
  const cls = cx(className, size, type);

  return (
    <ButtonEl className={cls} style={style} onClick={onClick}>
      {children}
    </ButtonEl>
  );
};
