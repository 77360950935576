import styled from 'styled-components';
import { BaseSection } from '../common/BaseSection';
import { mobileWidthBreakPoint, scrollbarHidden } from '../../styles';

export const Container = styled(BaseSection)`
  background: #0e0921;
`;

export const Content = styled.div`
  padding: 88px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    background: linear-gradient(178deg, #fff 2%, rgba(255, 255, 255, 0.37) 133%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .desc {
    font-size: 20px;
    line-height: 28px;
    margin-top: 16px;
    color: rgba(255, 255, 255, 0.5);
  }

  .cards {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .card {
      flex: 1 0 272px;
      border-radius: 8px;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.08);
      cursor: pointer;
      display: flex;
      flex-direction: column;

      .img-wrapper {
        width: 100%;
        height: 160px;
        overflow: hidden;
        
        .img {
          width: 100%;
          height: 100%;
          transition: transform 0.5s ease;
        }
      }

      .content {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: 20px;
          line-height: 28px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.8);
        }

        .check-action {
          font-size: 16px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: rgba(255, 255, 255, 0.4);
          margin-top: 45px;

          i {
            font-size: 12px;
            margin-left: 4px;
            height: 20px;
          }
        }
      }

      &:not(:first-child) {
        margin-left: 24px;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.12);
  
        .img {
          transform: scale(1.2);
        }

        .content {
          .check-action {
            color: #3f85ff;
          }
        }
      }
    }
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    & {
      padding: 44px 0;
    }

    .title {
      font-size: 24px;
      line-height: 36px;
    }

    .desc {
      font-size: 15px;
      line-height: 22px;
      margin-top: 12px;
    }

    .cards {
      padding: 0 24px;
      margin-top: 32px;
      overflow-x: auto;
      ${scrollbarHidden}
    }
  }
`;
