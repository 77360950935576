import React from 'react';
import { Colorcheck16 } from '@ali/we-icons';
import { FeatureBody, FeatureWrapper } from './styles';

interface Feature {
  icon: string
  title: string;
  highlightTitle: string;
  tips: string[];
  pcImg: string;
  mbImg: string;
}

const features: Feature[] = [
  {
    icon: 'https://img.alicdn.com/imgextra/i4/O1CN01IoxKgb1DYTHPMZCX2_!!6000000000228-55-tps-36-36.svg',
    title: '企业知识一站式',
    highlightTitle: '存储与管理',
    tips: [
      '文档、表格一键保存至知识库，零散文件统一沉淀',
      '支持直接拖拽移动文档，高效整理企业知识',
      '知识库在成员间灵活分享，团队经验有效传承与复用',
    ],
    pcImg: 'https://img.alicdn.com/imgextra/i4/O1CN01EBSK9p1Rh5Lgjess8_!!6000000002142-2-tps-2160-1452.png',
    mbImg: 'https://img.alicdn.com/imgextra/i2/O1CN010pI3YR294vRbYi1YC_!!6000000008015-2-tps-1039-665.png',
  },
  {
    icon: 'https://img.alicdn.com/imgextra/i3/O1CN01ebTEAR1YngMa9zEFe_!!6000000003104-55-tps-36-36.svg',
    title: '全面的数据资产',
    highlightTitle: '安全管控',
    tips: [
      '从文档、知识库到企业，三级安全管控全面覆盖',
      '文档操作日志支持查询，高危操作有迹可循',
      '评论、复制等权限灵活调整，安全防护精准配置',
    ],
    pcImg: 'https://img.alicdn.com/imgextra/i1/O1CN01w0FjtL1V83WGZGBCX_!!6000000002607-2-tps-2160-1452.png',
    mbImg: 'https://img.alicdn.com/imgextra/i2/O1CN01yVmwGe1l0IfAvpWq8_!!6000000004756-2-tps-1440-922.png',
  },
  {
    icon: 'https://img.alicdn.com/imgextra/i2/O1CN01XGtqKT1uCVlVyEysV_!!6000000006001-55-tps-36-36.svg',
    title: '高效协作，实现',
    highlightTitle: '业务提效',
    tips: [
      '一份文档多人同时在线编辑，内容实时保存',
      '10W+ 表格数据流畅处理，支持 400+ 常用公式',
      '多维表轻松搭建可视化数据库，整合分析复杂数据',
    ],
    pcImg: 'https://img.alicdn.com/imgextra/i3/O1CN01biMcP322Zsu6oC4XK_!!6000000007135-2-tps-2160-1452.png',
    mbImg: 'https://img.alicdn.com/imgextra/i4/O1CN0102MTBZ1dDZCxLK9Aj_!!6000000003702-2-tps-1440-922.png',
  },
  {
    icon: 'https://img.alicdn.com/imgextra/i4/O1CN01g1sWVf1lFPjWoZQHa_!!6000000004789-55-tps-36-36.svg',
    title: '打通企业业务',
    highlightTitle: '系统与数据',
    tips: [
      '开放海量 API 接口，与企业系统实现数据互通',
      '可脱离钉钉底座，与企业自有 OA 底座进行集成',
      '全面开放，根据企业情况打造最适配的知识管理体系',
    ],
    pcImg: 'https://img.alicdn.com/imgextra/i2/O1CN01l4iz8Y1VVs73DIBa7_!!6000000002659-2-tps-2160-1452.png',
    mbImg: 'https://img.alicdn.com/imgextra/i1/O1CN01SK2elx1tSFlKRg2Gf_!!6000000005900-2-tps-1440-922.png',
  },
];

export const Features = () => {
  return (
    <>
      {features.map((feature) => (
        <FeatureWrapper key={feature.title}>
          <FeatureBody>
            <div className="left">
              <div
                className="icon"
                style={{ background: `center / cover no-repeat url(${feature.icon}` }}
              />
              <div className="title">
                {feature.title}
                <span className="highlight">{feature.highlightTitle}</span>
              </div>
              <div className="tips">
                {feature.tips.map((tip) => (
                  <div className="tip" key={tip}>
                    <Colorcheck16 />
                    {tip}
                  </div>
                ))}
              </div>
            </div>
            <div className="right">
              <img className="pc-img" src={feature.pcImg} alt="" />
              <img className="mb-img" src={feature.mbImg} alt="" />
            </div>
          </FeatureBody>
        </FeatureWrapper>
      ))}
    </>
  );
};
