import styled from 'styled-components';
import { COLORS } from '@ali/we-design-token';
import { BaseSection } from '../common/BaseSection';
import { mobileWidthBreakPoint } from '../../styles';

export const Container = styled(BaseSection)`
  background: #f5f5f5;
`;

export const Content = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .record {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: ${COLORS.LEVEL2};

    img {
      height: 24px;
      margin-right: 4px;
    }

    a {
      text-decoration: none;

      &:hover {
        color: ${COLORS.LEVEL1};
      }
    }
  }

  @media (max-width: ${mobileWidthBreakPoint}) {
    padding: 18px 0;
  }
`;
