import React from 'react';
import { AppCommonEntry } from '../components/AppCommonEntry';
import { Cover } from './components/Cover';
import { Container, GlobalStyle } from './styles';
import { Apps } from './components/Apps';
import { Features } from './components/Features';
import { Solutions } from './components/Solutions';
import { Examples } from './components/Examples';
import { Customers } from './components/Customers';
import { Links } from './components/Links';
import { Footer } from './components/Footer';

export const HomeApp = () => {
  return (
    <>
      <GlobalStyle />
      <AppCommonEntry>
        <Container>
          <Cover />
          <Apps />
          <Features />
          <Solutions />
          <Examples />
          <Customers />
          <Links />
          <Footer />
        </Container>
      </AppCommonEntry>
    </>
  );
};
