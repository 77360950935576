import React from 'react';
import { Container, Content } from './styles';

interface SolutionItem {
  img: string;
  link: string;
  title: string;
}

const solutions: SolutionItem[] = [
  {
    img: 'https://img.alicdn.com/imgextra/i3/O1CN019dyVMU1q7h7fRKCbZ_!!6000000005449-0-tps-408-240.jpg',
    link: 'https://alidocs.dingtalk.com/i/nodes/YndMj49yWjDPmzk9I3G1N4RaJ3pmz5aA?utm_scene=team_space',
    title: '告别混乱！让物资管理一目了然！',
  },
  {
    img: 'https://img.alicdn.com/imgextra/i4/O1CN01xSmsj71f5ROnw9KCA_!!6000000003955-0-tps-408-240.jpg',
    link: 'https://alidocs.dingtalk.com/i/nodes/EpGBa2Lm8a3zjDKqFll7apyyVgN7R35y?utm_scene=team_space',
    title: '制作导师评估单，高效解决新员工试用期跟进管理',
  },
  {
    img: 'https://img.alicdn.com/imgextra/i3/O1CN01ge1fOi1V8yIK6GP1K_!!6000000002609-0-tps-408-240.jpg',
    link: 'https://alidocs.dingtalk.com/i/nodes/lo1YvX0prG98kzD6MBz9JPw7xzbmLdEZ?utm_scene=team_space',
    title: '全场景的电商运营必备模板',
  },
];

export const Solutions = () => {
  return (
    <Container>
      <Content>
        <div className="title">全方位场景解决方案</div>
        <div className="desc">一站式文档智能，打造全场景高效工作流</div>
        <div className="cards">
          {solutions.map((item) => (
            <div key={item.title} className="card" onClick={() => window.open(item.link, '_blank')}>
              <div className="img-wrapper">
                <div
                  className="img"
                  style={{ background: `center / cover no-repeat url(${item.img})` }}
                />
              </div>
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="check-action">
                  查看详情<i className="dd-home-head-icon">&#xe871;</i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Content>
    </Container>
  );
};
