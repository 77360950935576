import React, { type ReactNode, PureComponent } from 'react';
// import { logError, slsLogger } from '../../common/utils/logger';
// import { debug } from '../../common/utils/debug';
import { PageError } from '../PageEmpty';

export interface ErrorBoundaryProps {
  children: ReactNode;
  // tag?: string;
  // isGlobal?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // componentDidCatch(error: any, errorInfo: any) {
  //   const {
  //     tag,
  //     isGlobal,
  //   } = this.props;
  //   try {
  //     // logError({
  //     //   category: 'ErrorBoundary',
  //     //   message: error.message,
  //     //   error,
  //     // });
  //     // slsLogger.warn({
  //     //   name: 'ErrorBoundary',
  //     //   isGlobal,
  //     //   message: error?.message || '',
  //     //   errType: typeof error,
  //     //   value: tag || '',
  //     //   stack: error?.stack || '',
  //     //   componentStack: errorInfo?.componentStack || '',
  //     // });

  //     // const subtype = isGlobal ? MobileGlobalErrorBoundary : MobilePartialErrorBoundary;

  //     // vipAlarm({
  //     //   subtype,
  //     //   detail: {
  //     //     errorTag: tag || '',
  //     //     error,
  //     //     componentStack: errorInfo?.componentStack || '',
  //     //     errorTypeof: typeof error,
  //     //   },
  //     // });

  //     // debug.error(error, errorInfo);
  //   } catch (e) {
  //     // nothing
  //   }
  // }

  handleResetError = () => {
    // this.setState({ hasError: false });
    // 直接刷新整个页面, 不做局部重新渲染
    setTimeout(
      () => window.location.reload(),
      10,
    );
  };

  render() {
    if (this.state.hasError) {
      return (
        <PageError fullScreen />
      );
    }

    return this.props.children;
  }
}

